<!-- 对话基础布局 -->
<!--<script src="../../utils/WebIM/WebIM.js"></script>-->
<template>
  <div class="layout_dialog">
    <!-- 会话布局 -->
    <div class="title_group">
      <div class="input_title_box">
        <div class="basic_input_group" v-if="dialog_type === 'group' && $store.state.dialog_data">
          <!-- {{$store.state.dialog_data && $store.state.dialog_data.chatName}} ({{$store.state.dialog_data.users}}) -->
          {{$store.state.chat_detail && $store.state.chat_detail.chat && $store.state.chat_detail.chat.chatName}} ({{$store.state.dialog_data.users}})
        </div>
        <div class="basic_input_group" v-if="dialog_type === 'single'">
          <!-- <div class="text">{{message_box_unit.name}}</div> -->
          {{single_name}}
        </div>
        <div class="edit cp" v-show="dialog_type === 'group' && 0" @click="basicInputFocus" >点击修改</div>
        <div class="do_not_distrub flex-shrink-0"
          v-show="$store.state.chat_detail && $store.state.chat_detail.isdisturb"
        ></div>
      </div>
      <div class="fun_group">
        <div class="lock" v-show="0"></div>
        <div class="fun cp" @click="switchDrawer"
          v-show="!isSystemUser"
        ></div>
      </div>
    </div>
    <div class="content_group">
      <!-- 公告开关 -->
      <div
        v-if="$tools.getChatType() === 1"
        class="announcement_switch_group cp"
        :class="{on: announcement_switch, new_msg: message_list_data && message_list_data.chat && message_list_data.chat.readflag}"
        @click="openAnnouncement"
      >
        <div class="read_flag" v-show="message_list_data && message_list_data.chat && message_list_data.chat.readflag"></div>
        <div class="text">展示公示板</div>
        <!-- <img src="@/assets/images/dialog/announcement_close.png" alt="" class="switch close" v-show="announcement_switch"> -->
        <img src="@/assets/images/dialog/announcement_open.png" alt="" class="switch" v-show="!announcement_switch">
      </div>
      <!-- 公告 -->
      <div
        class="announcement_group"
        :class="{on: announcement_switch}"
      >
        <public-notice
          v-if="announcement_switch"
          ref="public_notice"
          @handleClose="closeAnnouncement"
        ></public-notice>
        <!-- <FunTaskAnnouncement ref="annc_component"
          v-else
        ></FunTaskAnnouncement> -->
      </div>
      <!-- 主体 -->
      <div class="dialog_group">
        <div class="dialog_box" ref="dialog_box">
          <router-view ref="dialog_scroll" class="router_view"></router-view>
          <div
            v-if="dialogData.unreadNumber !== 0"
            class="unread_message_group cp"
            @click.stop="checkUnreadNumber()"
          >
            {{ dialogData.unreadNumber }}条新消息
          </div>
          <!-- todo [侧边栏] 开发中 -->
          <transition name="sidebar">
            <side-bar
              class="side_bar_wrapper"
              v-if="switch_sidebar"
            ></side-bar>
          </transition>
          <!-- todo [侧边栏开关] 开发中 -->
          <transition name="sidebar">
            <div class="msg_group" v-if="1">
              <!-- <msgNotice></msgNotice>  -->
              <div
                class="notice_group cp no_notice"
                @click.stop="openSidebar();"
              >
                <template v-if="chat_detail && chat_detail.chat && chat_detail.chat.sidebarflag">
                  <img src="@/assets/images/dialog/notice.png" alt="" class="notice">
                  <div class="msg_number">{{sidebarNumber(chat_detail.chat.sidebarflag)}}</div>
                </template>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <!-- 功能区 -->
      <div
        class="fun_group"
        v-show="!isSystemUser"
      >
        <operating-space
          :dialog="dialog"
        ></operating-space>
        <!-- <MsgFun
        ></MsgFun> -->
      </div>

      <!-- 抽屉 -->
      <el-drawer
        custom-class="dialog_drawer_el_wrapper"
        :visible.sync="drawer"
        :with-header="false"
        :modal="false"
        :modal-append-to-body="false"
        :wrapperClosable="false"
        size="300px"
        @open="openDrawer();"
      >
        <div class="drawer_wrapper"
          @click.stop="$api.stopPropagation($event);"
        >
          <DialogDrawer v-show="dialog_type === 'group'" @closeDrawer="closeDrawer" ref="drawerGroup"></DialogDrawer>
          <DialogDrawerSingle v-show="dialog_type === 'single'" @closeDrawer="closeDrawer" ref="drawerSingle"></DialogDrawerSingle>
        </div>
      </el-drawer>
    </div>
   </div>
</template>
<script>
import DialogDrawer from '@/components/dialog/drawer.vue';
import DialogDrawerSingle from '@/components/dialog/drawerSingle.vue';
import SideBar from '@/components/dialog/Sidebar.vue';
import PublicNotice from '@/views/fun_task/PublicNotice.vue';
import OperatingSpace from '@/components/dialog/OperatingSpace/Container.vue';
import store from '@/store'
import { Dialog } from '@/classes'
import tools from '@/api/tools'
import router from '@/router'
export default {
  components: {
    DialogDrawer,
    DialogDrawerSingle,
    SideBar,
    PublicNotice,
    OperatingSpace,
  },
  data(){
    return{
      focus: false,
      drawer: false, //抽屉
      dialog_type: 'single', // 会话类型 single / group
      announcement_switch: false, //公告开关
      chatName: this.message_box_unit ? this.message_box_unit.name : '', //会话名（修改用）
      single_name: '',
      dialogData: store.state.dialogData,
      dialog: undefined
    }
  },
  computed: {
    message_box_unit(){
      return this.$store.state.message_box_unit;
    },
    message_box_list(){
      return this.$store.state.message_box_list;
    },
    user_data(){
      return this.$store.state.user_data;
    },
    switch_sidebar(){
      return this.$store.state.switch_sidebar;
    },
    message_list_data(){
      return this.$store.state.message_list_data;
    },
    chat_detail(){
      return this.$store.state.chat_detail;
    },
    // [单聊时]是否是系统用户
    isSystemUser(){
      let status = false;
      if(this.$tools.getChatType() == 1){
        status = false;
      }else{
        status = this.$api.isSystemUser(this.$api.moduleTask.getRouteParamsId());
      }
      return status;
    },
  },
  watch: {
    drawer: function(val){
      if(val){
        this.$nextTick(function(){
          this.$refs.drawerGroup.inputNameBlur();
          // 点击关闭抽屉
          document.querySelector('.el-drawer__container').addEventListener('click', this.closeDrawer)
        })
      }else{
        this.$nextTick(function(){
          if(document.querySelector('.el-drawer__container')){
            document.querySelector('.el-drawer__container').removeEventListener('click', this.closeDrawer)
          }
        })

      }
    },
    '$route': function(nv){
      /**
       * 路由变化关闭抽屉
       */
      this.drawer = false
      this.$store.commit('set_switch_sidebar', false)
       /**
       * 切换会话类型
       */
      if (!nv) {
        return
      }
      if (nv.name === 'fun_task_dialog_group') {
        this.dialog_type = 'group'
      }
      if (nv.name === 'fun_task_dialog_single') {
        this.dialog_type = 'single'
      }
      this.init()
    },
    message_box_unit: {
      handler: function(){},
      deep: true,
    },
    message_box_list: {
      handler: function(){},
      deep: true,
    },
    user_data: {
      handler: function(val){
        if (val) {
          let { friendRemark, userName, updateremark } = val
          this.single_name = updateremark ? friendRemark : userName
        }
      },
      deep: true,
    },
    '$route.params.id':{
      handler: async function(){
        await this.announcementInit();
      }
    },
    message_list_data: {
      handler: function(){},
      deep: true,
    },
    chat_detail: {
      handler: function(){},
      deep: true,
    }
  },
  methods:{
    /**
     *  清除复制控件的数据
     */
    clearWidgetData () {
      tools.removeCookie('copyWidgetData')
      store.commit('set_copyWidgetList', [])
    },
    /**
     * 加载复制控件的数据
     */
    loadCopyWidgetData () {
      const copyWidgetData = tools.getCookie('copyWidgetData')
      if ((copyWidgetData ?? '') === '') {
        this.clearWidgetData()
        return
      }
      const { userId, list } = copyWidgetData
      const currentUserId = tools.getUserId()
      if (Number(userId) !== Number(currentUserId)) {
        this.clearWidgetData()
        return
      }
      store.commit('set_copyWidgetList', list)
    },
    // 查看未读消息
    checkUnreadNumber() {
      this.$refs.dialog_scroll.checkUnreadNumber()
    },
    /**
     * 关闭抽屉
     */
    closeDrawer () {
      this.drawer = false
    },
    /**
     * 编辑会话名
     */
    async basicInputFocus () {
      this.$refs.basicInput.inputFocus()
      this.chatNameEdit() // 编辑会话名
    },
    /**
     * 会话名变化
     */
    chatNameInput (chatName) {
      this.chatName = chatName;
    },
    /**
     * 编辑会话名
     */
    async chatNameEdit () {
      if (this.chatName) {
        let chatId = this.message_box_unit.chatId
        let chatName = this.chatName
        let data = { chatId, chatName }
        await this.$http.request('/project/chatNameEdit',data)
        this.changeChatName()
      } else {
        this.$toast({
          icon:'none',
          title:'提示',
          desc:'请输入群聊名称',
          duration:2000
        })
      }
    },
    /**修改群聊会话名 */
    changeChatName () {
      if (!this.message_box_unit) {
        return
      }
      let { chatId } = this.message_box_unit
      let message_box_list = this.$tools.deepClone(this.$store.state.message_box_list)
      let index = message_box_list.findIndex((item) => {
        return item.chatId === chatId
      })
      if (index !== -1) {
        message_box_list[index].name = this.chatName
      }
      this.$store.commit('set_message_box_list', message_box_list)
    },
    async init () {
      let { dialog_type, dialog } = this
      if ((dialog ?? '') === '') {
        const dialogData = new Dialog()
        this.$set(this, 'dialog', dialogData)
      }
      const { $route } = this
      const { id } = $route.params
      this.dialog.chatType = Number(tools.getChatType())
      this.dialog.chatId = Number(tools.atob(id))
      if (dialog_type === 'single') {
        let userId = this.$api.moduleTask.getRouteParamsId()
        let res = await this.$api.getUserData({userId})
        let user_data = {}
        if (res.contents && res.contents.user) {
          user_data = res.contents.user
          this.$store.commit('set_user_data', user_data);
          this.single_name = user_data.friendRemark || user_data.userName;
        }
      }
      const { workteam_list, friends_list } = store.state
      // 不在工作组列表中概念跳转到频道页
      if (this.dialog.chatType === 1) {
        const aim = workteam_list.find((unit) => {
          return unit.chatId === this.dialog.chatId
        })
        if (aim === undefined) {
          router.push({
            name: 'fun_task_index'
          })
        }
      }
      // 不在好友列表中概念跳转到频道页
      if (this.dialog.chatType === 0) {
        const aim = friends_list.find((unit) => {
          return Number(unit.friendUserId) === Number(this.dialog.chatId)
        })
        if (aim === undefined && this.dialog.chatId !== 5494) {
          router.push({
            name: 'fun_task_index'
          })
        }
      }
    },
    /** 获取用户信息 */
    async getUserData(userid){
      await this.$store.dispatch('get_user_data', userid);
    },
    /**修改好友昵称 */
    async changeSingleName(){
      let {single_name} = this;
      if(single_name !== this.user_data.friendRemark){
        let friendRemark = single_name;
        let friendUserId = this.$api.moduleTask.getRouteParamsId();
        let res = await this.$http.request('/projectFriends/updateFriend', {friendUserId,friendRemark});
        if(res.message === 'success'){
          let user_data = this.user_data;
          user_data.friendRemark = single_name;
          this.$store.commit('set_user_data', user_data);
          let userName = single_name || user_data.userName;
          this.changeMessageBoxUserName(userName);
          this.changeMessageUserName(userName);
        }
      }
    },
    /**当前聊天的好友名更换(单聊) */
    async changeMessageUserName(userName){
      let message_list = this.$tools.deepClone(this.$store.state.message_list);
      if(message_list && message_list.length){
        let chatId = this.$api.moduleTask.getRouteParamsId();
        message_list.forEach( item => {
          if(item.fromUserId === chatId){
            item.userName = userName;
          }
        })
        this.$store.commit('set_message_list', message_list);
      }
    },
    /**更换消息盒子备注名 */
    async changeMessageBoxUserName(userName){
      if(!this.message_box_unit){
        return;
      }
      let {chatId} = this.message_box_unit;
      let message_box_list = this.$tools.deepClone(this.$store.state.message_box_list);
      let index = message_box_list.findIndex( item => {
        return item.chatId === chatId && item.chatType === 0;
      })
      if(index !== -1){
        message_box_list[index].name = userName;
        this.$store.commit('set_message_box_list', message_box_list);
      }
    },
    switchDrawer(){
      let {dialog_type} = this;
      if(!this.drawer){
        let drawerEl;
        if(dialog_type === 'group'){
          drawerEl = this.$refs.drawerGroup;
        }else{
          drawerEl = this.$refs.drawerSingle;
        }
        if(drawerEl){
          drawerEl.init();
        }
      }
      this.drawer = !this.drawer
    },
    /**
     * @description: 关闭公告
     * @param {*}
     * @return {*}
     */
    closeAnnouncement(){
      let { annc_modified } = this.$store.state
      if (annc_modified) {
        this.$openConfirm({
          title:'当前已有内容修改,是否关闭?',
          desc:'已修改的内容不会保存',
          sure:'确定',
          cancel:'取消',
          onSure:() => {
            this.$store.commit('set_annc_modified', false) // 重置编辑状态
            this.announcement_switch = false
            this.$store.commit('set_annc_switch', false)
          }
        })
      } else {
        this.announcement_switch = false
        this.$store.commit('set_annc_switch', false)
      }

    },
    /**
     * @description: 打开公告
     * @param {*}
     * @return {*}
     */
    openAnnouncement () {
      this.announcement_switch = true
      this.$nextTick(function(){
        this.$refs.public_notice.init()
      })
    },
    /**
     * @description: 初始化公告相关数据
     * @param {*}
     * @return {*}
     */
    announcementInit(){
      this.$store.commit('set_annc_switch', false)
      this.announcement_switch = false
    },
    /**
     * @description: 打开侧边栏
     * @param {*}
     * @return {*}
     */
    openSidebar(){
      this.$store.commit('set_switch_sidebar', true);
    },
    /**
     * @description: 处理侧边栏消息数量
     * @param {*} num
     * @return {*}
     */
    sidebarNumber(num){
      if(num> 99){
        return '99+';
      }else{
        return num;
      }
    },
    openDrawer(){
      this.$nextTick(()=>{
        this.$utils.setPopoverZIndex('.el-drawer__wrapper');
      })
    }
  },
  async mounted(){
    /**
     * 切换会话类型
     */
    let routername = this.$route.name
    if (routername === 'fun_task_dialog_group') {
      this.dialog_type = 'group'
    }
    if (routername === 'fun_task_dialog_single') {
      this.dialog_type = 'single'
    }

    const { workteam_list } = store.state
    if (!workteam_list || workteam_list.length === 0) {
      await store.dispatch('get_workteam_list')
    }
    this.init()
    this.loadCopyWidgetData()
  },
}
</script>
<style lang="scss" scoped>
.layout_dialog{
  width:100%;
  height: 100%;
  // 会话
  @include flex;
  flex-direction: column;
  align-items: flex-start;
  .title_group{
    @include flexsb;
    @include bbox;
    align-items: center;
    flex-shrink: 0;
    height: 64px;
    padding-left: 20px;
    width:100%;
    background-color:#fff;
    z-index: 3;
    font-size:18px;

    font-weight:400;
    color:rgba(51,51,51,1);
    border-bottom: 2px solid $border_color;
    .input_title_box{
      @include flex;
      .basic_input_group{
        .single_name{
          @include bbox;
          @include transition;
          padding: 0 10px;
          font-size: 18px;
          line-height: 36px;
          border-radius: 4px;
          border: 1px solid transparent;
          width: 308px;
          &:hover{
            border: 1px solid #ccc;
          }
        }
      }
      &:hover{
        .edit{
          opacity: 1;
        }
      }
      .edit{
        @include transition;
        opacity: 0;
        font-size: 12px;
        padding-left: 10px;
        color: #666;
        // width: 15px;
        // height: 15px;
        // background: {
        //   image: url(~@/assets/images/common/title_edit.png);
        //   size: 100%;
        // }
      }
      .do_not_distrub{
        @include background();
        width: 12px;
        height: 12px;
        margin-left: 8px;
        background: {
          image: url(~@/assets/images/dialog/do_not_distrub.png);
        }
      }
    }
    .fun_group{
      @include flex;
      padding: {
        right: 40px;
      }
      flex-shrink: 0;
      .fun{
        background: {
          image: url(~@/assets/images/common/more.png);
          size: 20px 4px;
          repeat: no-repeat;
          position: center;
        }
        width:20px;
        height: 34px;
      }
    }


  }
  .content_group{
    @include flex;
    position: relative;
    flex-direction: column;
    flex:1;
    height:100%;
    width: 100%;
    overflow: hidden;
    background-color: $main_bg_color;
    // 公告开关
    .announcement_switch_group{
      @include u_sel_none;
      @include transition;
      position: absolute;
      z-index: 4;
      top: -2px;
      left: 50%;
      transform: translateX(-50%);
      &.on{
        top: auto;
        bottom: 0;
      }
      .switch{
        display: block;
      }
      .read_flag{
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $red_dot;
        top: 7px;
        left: 27px;
        z-index: 4;
      }
      .text{
        position: absolute;
        left: 40px;
        top:0;
        font-size: 12px;
        line-height: 22px;
        color: #888;
      }
      &.new_msg{
        // filter: drop-shadow(0px 0px 2px $main_blue);
      }
    }
    // 公告
    .announcement_group{
      @include transition;
      position: absolute;
      width: 100%;
      height: 100%;
      // top:0;
      left:0;
      z-index: 5;
      // transform: translateY(-100%);
      top: -100%;
      opacity: 0;
      &.on{
        // transform: translateY(0);
        top:0;
        opacity: 1;
      }
    }
    .dialog_group{
      @include flex;
      align-items: stretch;
      overflow: hidden;
      height: 100%;
      width:100%;
      flex: 1;
      .dialog_box{
        @include flex;
        @include bbox;
        @include transition;
        align-items: stretch;
        position: relative;
        overflow: hidden;
        width:100%;
        flex: 1;
        padding: 20px;
        padding-right: 0;
        padding-top: 30px;
        .unread_message_group{
          z-index: 9;
          position: absolute;
          bottom: 24px;
          //bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          //left: 0;
          font-size: 12px;
          line-height: 20px;
          padding: 0 10px;
          border-radius: 10px;
          background-color: #fff;
          box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.3);
          color: #666;
        }
        .router_view{
          @include bbox;
          z-index: 2;
          width: 100%;
          flex: 1;
          padding-right: 20px;
        }
        .msg_group{
          @include flex;
          flex-shrink: 0;
          justify-content: center;
          position: relative;
          z-index: 2;
          .notice_group{
            @include flex;
            justify-content: flex-end;
            position: relative;
            z-index: 9;
            width:42px;
            height:42px;
            &.no_notice{
              @include background;
              width: 18px;
              height: 42px;
              background-image: url(~@/assets/images/dialog/no_msg.png);

            }
            .notice{
              position: absolute;
              width:42px;
              height:42px;
              display: block;
              z-index: 2;
            }
            .msg_number{
              @include bbox;
              position: relative;
              top: 0;
              width: 45px;
              flex-shrink: 0;
              font-size:14px;
              font-weight:400;
              // color:rgba(0,0,0,1);
              color:#fff;
              z-index: 3;
              padding-top: 0px;
              padding-right: 5px;
              text-align: center;
            }
            .notice_detail_group{
              @include flex;
              @include bbox;
              justify-content: center;
              position: absolute;
              left: -230px;
              width:200px;
              height:40px;
              background:#ABADAF;
              border-radius:20px;
              .icon_arrow{
                width: 10px;
                height: 12px;
                display: block;
              }
              .text{
                padding: 0 10px;
                border-right: 1px solid #fff;
                margin-right: 10px;
                font-size:14px;
                line-height: 16px;

                font-weight:400;
                color:rgba(255,255,255,1);
                white-space: nowrap;
              }
              .close{
                display: block;
                width:10px;
                height: 10px;
              }
            }
          }
        }
      }
      .side_bar_wrapper{
        position: absolute;
        right: 0;
        height: 100%;
        top:0;
        z-index: 5;
      }

    }
    .fun_group{
      width: 100%;
      flex-shrink: 0;
    }
    .drawer_wrapper{
      @include hidescrollbar;
      @include bbox;
      height: 100%;
      width: 100%;
      padding: 0 30px;
      overflow-y: auto;

    }
  }
}
.drawer_el_wrapper{
  height: 100%;
}
</style>
<style lang="css">
  .layout_dialog .title_group .el-input__inner{
    border:none;
    background-color: transparent;
    font-size:18px;

    font-weight:400;
    color:rgba(51,51,51,1);
    width:200px;
  }
  .layout_dialog .v-modal{
    position: absolute;
  }
  .layout_dialog .el-drawer__wrapper{
    position: absolute;
  }
  /* .layout_dialog .el-drawer__body:focus{
    outline: none;
  } */
  :focus{
    outline: none;
  }
  .drawer_el_wrapper .el-drawer__body{
    height: 100%;
  }
  .dialog_drawer_el_wrapper .el-drawer__body{
    overflow-y: auto;
  }
</style>
<style lang="scss" scoped>
.sidebar-enter-active, .sidebar-leave-active {
  transition: all .2s;
}
.sidebar-enter, .sidebar-leave-to /* .sidebar-leave-active below version 2.1.8 */ {
  opacity: 0;
  // transform: translateX(100%);
}
</style>
